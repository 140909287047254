import { useModule } from '@/controllers/manager'
import { messageAlerts } from '@/middlewares/responseHandler'
import store from '@/store/index'

export default {
  namespaced: true,
  state: {
    module: 'pedidos',
    loading: false,
  },
  getters: {},
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async fetchPedidos({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.getAll(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchPedido({ state, commit }, payload) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        return await controller.getById(payload)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return []
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async addPedido({ commit, state }, pedidoData) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return await controller.create(pedidoData)
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async deletePedido({ commit, state }, data) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.deleteById(data)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async changeStatus({ commit, state }, data) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.changeStatus(data)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async printPedido({ commit, state }, data) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        let res = await controller.printPedido(data)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return res
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async duplicatePedido({ commit, state }, data) {
      store.commit('app/RESET_ALERT')
      commit('SET_LOADING', true)
      const controller = useModule(state.module)
      try {
        await controller.duplicatePedido(data)
        store.commit('app/SET_ALERT', messageAlerts.a200Alert)
        return null
      } catch {
        store.commit('app/SET_ALERT', messageAlerts.errorAlert)
        return null
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
